// import React, { Component } from 'react'
import React, { Component, useState, useRef } from 'react';
import { connect } from "react-redux";
import OtherService from '../../services/OtherService';
import TrackOrderByQrService from '../../services/TrackOrderByQr.service';
import style from "./Login.style";
import CustomerService from "../../services/Customer.service";
import { withRouter } from "../../components/Reusable/WithRouter";
import CustomerAction from "../../redux/actions/Customer.action";
import CommonAction from '../../redux/actions/Common.action';
import Common from '../../hoc/Common.hoc';
import TimerComponent from '../../components/Timer/LoginTimer';
import config from '../../config/emrok.config';
import MrAction from '../../redux/actions/Mr.action';
import DoctorAction from '../../redux/actions/Doctor.action';
import StockistAction from '../../redux/actions/Stockist.action';
import CallCenterAction from '../../redux/actions/CallCenter.action';
import ManufacturAction from '../../redux/actions/Manufactur.action';
import OrderAction from '../../redux/actions/Order.action';

class Login extends Component {
    constructor(props) {
        super(props)
        this.firstRef = React.createRef();
        this.secondRef = React.createRef();
        this.thirdRef = React.createRef();
        this.fourthRef = React.createRef();
        // this.inputRefs = []; // Array to store input field references

        this.state = {
            mobileno: "",
            isMobileVerified: false,
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            // currentInputIndex: 0,
            combineOtpValidLength: ""
        }
        console.log(props)
    }

    componentDidMount() {
        const orderid = this.props.param.orderid;
        
        TrackOrderByQrService.getCustomerDetails({orderid:orderid}).then(res => {
            console.log(res)
            if (res && res.data && res.data.success && res.data.data) {
                console.log(res.data.data,"123456");
                const mobileno = res.data.data.mobileno;
                this.setState({ mobileno: mobileno.toString() })
                this.setState({ isMobileVerified: true })

                // if (mobileno.length == 10) {
                //     OtherService.generateOtp({ mobileno: mobileno }).then(res => {
                //         console.log(res)
                //         if (res && res.data && res.data.success) {
                //             this.setState({ isMobileVerified: true })
                //         }
                //     }).catch(err => {
                //         console.log(err)
                //     });
                // }
            }
        }).catch(err => {
            console.log(err)
        });

        console.log(this.props, "propss")
    }
    /**
     * 
     * @param {Object} event 
     */
    setNumber = (event) => {
        const val = event.target.value;
        const reg = new RegExp('^[0-9]+$');
        if (!val || (reg.test(val) && val.length <= 10)) {
            this.setState({ mobileno: val })
        }
    }

    verifyMobileSubmit = (e) => {
        e.preventDefault();
        const { mobileno } = this.state;
        const data = { mobileno }
        if (mobileno.length == 10) {
            OtherService.generateOtp(data).then(res => {
                console.log(res)
                if (res && res.data && res.data.success) {
                    this.setState({ isMobileVerified: true })
                }
            }).catch(err => {
                console.log(err)
            });
        }

    }

    // mobileVerify = () => {
    //     const { mobileno } = this.state;
    //     const isValid = mobileno.length == 10;
    //     return (
    //         <section className="login-part-st">
    //             <div className="container">

    //                 <div className="login-form">
    //                     <div className="muown-logo">
    //                         <img src={require("../../assets/images/logo-image.png")} />
    //                     </div>
    //                     {/* changes by smarajit */}
    //                     <h2 className="pb-3">Welcome to MYOWNN</h2>

    //                     <div className="mobile-inpt-pt pt-3">
    //                         <form>
    //                             <div className="cl-inpt-part-st"><input onChange={(event) => {
    //                                 this.setNumber(event)
    //                             }} value={mobileno} type="text" className="form-control mbl-inpt-part" placeholder="Enter Mobile Number" /></div>
    //                         </form>
    //                     </div>

    //                     <div className="know-mr-btn pt-3">
    //                         <p><a href={config.knowMoreLink}>Know more</a></p>
    //                     </div>

    //                     <div className="pt-3">
    //                         <a type="button" disabled={true} style={{ cursor: isValid ? "pointer" : "default" }} className={isValid ? "login-btn-usr" : "login-btn-usr login-disable"} onClick={this.verifyMobileSubmit}>Login</a>
    //                     </div>
    //                 </div>
    //             </div>
    //         </section>
    //     )
    // }

    combineOtp = () => {
        const { otp1, otp2, otp3, otp4 } = this.state;
        const otp = otp1 + otp2 + otp3 + otp4;
        return otp;
    }
    changeOtp = (event, ref) => {
        const { name, value } = event.target;
        const callBack = () => {
            const otp = this.combineOtp();
            if (otp.length == 4) {
                this.setState({ combineOtpValidLength: true })
            }
            else {
                this.setState({ combineOtpValidLength: false })
            }
            if (ref && value) {
                ref.focus()
            }
        };
        if (value.length <= 1) {
            this.setState({ [name]: value }, callBack)
        }
    }

    verifyOtpSubmit = () => {
        const otp = this.combineOtp();
        const mobileno = this.state.mobileno;
        const order_id = this.props.param.orderid;
        const data = {
            mobileno, otp
        }

        console.log(data,"data");
        const otherLogin = () => {

            // const prescribedDoctor = this.props.param.prescribedDoctor;
            // console.log(prescribedDoctor, mobileno);
            OtherService.customerLoginNew({
                mobileno,
                order_id
            }).then(async (res) => {
                console.log(res, 111);
                if (res && res.data && res.data.success && res.data.data) {

                    console.log(res.data.data, "details data");

                    // if (res.data.data.userType === "MR") {
                        this.props.setCustomer(res.data.data)
                        this.props.setToken(res.data.data.token)
                        this.props.saveSelectedOrders(res.data.data.orders)

                         this.props.navigate("/customer/track-info-individual")
                    // }


                    // this.props.setCustomer(res.data.data._customer)
                    // this.props.setToken(res.data.data.token)
                    // this.props.navigate("/customer/registration")
                }
            }).catch(err => {
                console.log(err)
            })
        }
        OtherService.verifyOtp(data).then(res => {
            console.log(res, 7878);

            if (res && res.data && res.data.success) {
                // const drId = this.props.param.dr_id;
                otherLogin();

            }
            else if (res && res.data && !res.data.success) {
                this.props.hoc.customAlert(res.data?.message || "Something went wrong.", false)
            }
        }).catch(err => {
            console.log(err)
        })
    }






    render = () => {

        const { isMobileVerified, otp1, otp2, otp3, otp4, combineOtpValidLength } = this.state;
        const resendOtp = () => {
           

            const { mobileno } = this.state;
            const data = { mobileno }
            // alert(mobileno.toString().length);
            if (mobileno.length == 10) {
                // alert(mobileno);
                OtherService.generateOtp(data).then(res => {
                    console.log(res)
                    if (res && res.data && res.data.success) {
                        console.log(res);
                        this.setState({ isMobileVerified: true })
                    }
                }).catch(err => {
                    console.log(err)
                });
            }
        }
        return (
            <>
                <section className="login-part-st">
                    <div className="container">

                        <div className="login-form">
                            <div className="muown-logo">
                                <img src={require("../../assets/images/logo-image.png")} />
                            </div>

                            <h2 className="pb-3">Welcome to MYOWNN</h2>
                            <h1 className="otp-part-pt">OTP SENT</h1>
                            <p className="otp-text-pt">Enter the OTP sent to you</p>

                            <form>
                                <div className="otp-clm-gap d-flex mb-3 mt-3">
                                    <div className="">
                                        <input type="text" ref={input => { this.firstRef = input }} id="otp-transpr-pt" onChange={(event) => this.changeOtp(event, this.secondRef)} value={otp1} name="otp1" tabIndex="1" />
                                    </div>
                                    <div className="">
                                        <input type="text" ref={input => { this.secondRef = input; }} id="otp-transpr-pt" onChange={(event) => this.changeOtp(event, this.thirdRef)} value={otp2} name="otp2" tabIndex="2" />
                                    </div>
                                    <div>
                                        <input type="text" ref={input => { this.thirdRef = input; }} id="otp-transpr-pt" onChange={(event) => this.changeOtp(event, this.fourthRef)} value={otp3} name="otp3" tabIndex="3" />
                                    </div>
                                    <div className="">
                                        <input type="text" ref={input => { this.fourthRef = input; }} id="otp-transpr-pt" onChange={(event) => this.changeOtp(event)} value={otp4} name="otp4" tabIndex="4" />
                                    </div>

                                </div>

                                <div className="resend-otp-fr-login">
                                    <span> <TimerComponent resendOtp={resendOtp} /> </span>
                                </div>
                                <div className="mb-3 mt-4">
                                    <a disabled={combineOtpValidLength ? false : true} style={combineOtpValidLength ? { ...style.btn } : null} className="form-control login-btn-usr" id="" onClick={() => this.verifyOtpSubmit()}> Done</a>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </>
        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer)),
        setToken: (token) => dispatch(CommonAction.setToken(token)),
        setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails)),
        setDoctor: (doctordetails) => dispatch(DoctorAction.setDoctor(doctordetails)),
        setStockist: (stockistdetails) => dispatch(StockistAction.setStockist(stockistdetails)),
        setCallCenter: (callcenterdetails) => dispatch(CallCenterAction.setCallCenter(callcenterdetails)),
        setManufactur: (manufacturdetails) => dispatch(ManufacturAction.setManufactur(manufacturdetails)),
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
    }
}

export default connect(null, mapDispatchToProps)(withRouter(Common(Login)))