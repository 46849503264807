import Base from './Base.service'
import { store } from '../redux/index.store'
import {
    GENERATE_OTP,
    MATCH_OTP,
    OTHER_LOGIN_API,
    GET_ORDER_DETAILS
} from './Slugs'


class TrackOrderByQrService extends Base {
  constructor(props) {
    super(props)
  }

  customerLogin(data) {
    return this.post(OTHER_LOGIN_API, data);
  }

  generateOtp(data){
    return this.post(GENERATE_OTP,data);
  }
  
  verifyOtp(data){
    return this.post(MATCH_OTP,data);
  }
  getCustomerDetails(data){
    return this.post(GET_ORDER_DETAILS,data);
  }
 
}

export default  new TrackOrderByQrService()