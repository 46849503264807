import {
    SET_MANUFACTUR,
    CLEAR_STATE
} from '../types/Manufactur.type'

const initState = {
    manufacturdetails: {},
    token: null
}

export default (state = initState, action) => {
    const { type, payload } = action
    switch (type) {
        case SET_MANUFACTUR:
            return Object.assign({}, state, { manufacturdetails: { ...payload } })
        case CLEAR_STATE:
            return initState;
        default:
            return state
    }
}