

import Base from './Base.service';
import {
    GET_PRODUCT,
    GET_MYOWN_PRODUCT
} from './Slugs'


class ProductService extends Base {
  constructor(props) {
    super(props)
  }

  getProducts(){
    return this.get(GET_PRODUCT);
  }
  getMyownnProducts(){
    return this.get(GET_MYOWN_PRODUCT)
  }
}

export default  new ProductService()