import {
    SET_MR,
    LOGOUT
    } from '../types/Mr.type'
    class MrAction {
      setMr = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        return { type: SET_MR, payload: data }
      }
      
      logout = ()=>{
        return { type: LOGOUT, payload: null }
      }
     
      
    }
    export default new MrAction()