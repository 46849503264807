import {
  LOADER_ACTION,
  TOKEN,
  LOGOUT
} from '../types/Common.type'

const initState = {
  isloading: false,
  token: null
}

export default (state = initState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOADER_ACTION:
      return Object.assign({}, state, { isloading: payload })
    case TOKEN:
      return Object.assign({}, state, { token: payload })
    case LOGOUT:
      return initState;
    default:
      return state
  }
}