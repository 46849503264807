import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from '../../components/Reusable/WithRouter'
import CustomerHeader from '../../components/layout/CustomerHeader'
import CustomerFooter from '../../components/layout/CustomerFooter'
import config from '../../config/emrok.config'
import { Link } from 'react-router-dom'
import Common from '../../hoc/Common.hoc'


class Feedback extends Component {
    constructor(props) {
        super(props)
        this.state = {
          rating:0
        }
    }

    componentDidMount = () => {
        const customer = this.props.CustomerReducer;
        const order = this.props.OrderReducer;

        console.log(customer,"customer from feedback screen");
        console.log(order,"order from feedback screen");
    }
    handleStarClick = (value) => {
        const customer = this.props.CustomerReducer;
        const order = this.props.OrderReducer;

        console.log(customer,"customer from feedback screen");
        console.log(order,"order from feedback screen");
    }
    handleSubmit = () =>{

      this.props.hoc.customAlertWithClick("Thank you for your feedback ", true)
    }

    render = () => {
      const  { rating } = this.state;
        return (
            <>
            <section className="feedback-form">
              <div className="container">
                <CustomerHeader />
                
                {/* Row End*/}
                <div className="row">
                  <div className="col-md-6 regfrm-bag-image d-lg-none">
                    {/* <img src="images/fdbk-image.png" className="" alt="" /> */}
                    <img src={`${config.serviceUrl}/images/myownn/fdbk-image.png`} className="" alt="" />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="page-title">
                    <h2 className="lg-title text-capitalize mb-20">Feedback Form</h2>
                  </div>
                </div>
                {/* Row End*/}
                <div className="row" />
                {/* Row End*/}
              </div>
            </section>


            <section className="write-review-pt">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="col-md-12">
                      <div className="feedback-select-box">
                        <form>
                          <div className="">
                            <label htmlFor="" className="form-label">
                              Select Category
                            </label>
                            <select
                              className="form-select feedBackSelect"
                              data-placeholder="Select an option"
                              name=""
                            >
                              <option value="" >Please Select</option>
                              <option value="Complain">Complain</option>
                              <option value="Order">Order</option>
                              <option value="Query">Query</option>
                            </select>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="review-part-ft mt-5">
                      <h2>Write a Review</h2>
                    </div>
                    <div className="review-start-pt">
                      <div className="row mt-3">
                        <div className="col-3 col-md-2 product-image-col">
                          <div className="product-dtls-image-box new-fdbc-part">
                            {/* <img src="images/medicine-emr.png" alt="" /> */}
                            <img src={`${config.serviceUrl}/images/myownn/medicine-emr.png`} className="" alt="" />
                          </div>
                        </div>
                        <div className="col-8 col-md-10 emrok-pro-detls">
                          <h5>MYOWNN</h5>
                          { <div className="rating-group">
                            <input
                              className="rating__input rating__input--none"
                              defaultChecked=""
                              name="rating2"
                              id="rating2-0"
                              defaultValue={0}
                              type="radio"
                            />
                            <label
                              aria-label="0.5 stars"
                              className="rating__label rating__label--half"
                              htmlFor="rating2-05"
                            >
                              <i className="rating__icon rating__icon--star fa fa-star-half" />
                            </label>
                            <input
                              className="rating__input"
                              name="rating2"
                              id="rating2-05"
                              defaultValue="0.5"
                              type="radio"
                            />
                            <label
                              aria-label="1 star"
                              className="rating__label"
                              htmlFor="rating2-10"
                            >
                              <i className="rating__icon rating__icon--star fa fa-star" />
                            </label>
                            <input
                              className="rating__input"
                              name="rating2"
                              id="rating2-10"
                              defaultValue={1}
                              type="radio"
                            />
                            <label
                              aria-label="1.5 stars"
                              className="rating__label rating__label--half"
                              htmlFor="rating2-15"
                            >
                              <i className="rating__icon rating__icon--star fa fa-star-half" />
                            </label>
                            <input
                              className="rating__input"
                              name="rating2"
                              id="rating2-15"
                              defaultValue="1.5"
                              type="radio"
                            />
                            <label
                              aria-label="2 stars"
                              className="rating__label"
                              htmlFor="rating2-20"
                            >
                              <i className="rating__icon rating__icon--star fa fa-star" />
                            </label>
                            <input
                              className="rating__input"
                              name="rating2"
                              id="rating2-20"
                              defaultValue={2}
                              type="radio"
                            />
                            <label
                              aria-label="2.5 stars"
                              className="rating__label rating__label--half"
                              htmlFor="rating2-25"
                            >
                              <i className="rating__icon rating__icon--star fa fa-star-half" />
                            </label>
                            <input
                              className="rating__input"
                              name="rating2"
                              id="rating2-25"
                              defaultValue="2.5"
                              type="radio"
                              defaultChecked=""
                            />
                            <label
                              aria-label="3 stars"
                              className="rating__label"
                              htmlFor="rating2-30"
                            >
                              <i className="rating__icon rating__icon--star fa fa-star" />
                            </label>
                            <input
                              className="rating__input"
                              name="rating2"
                              id="rating2-30"
                              defaultValue={3}
                              type="radio"
                            />
                            <label
                              aria-label="3.5 stars"
                              className="rating__label rating__label--half"
                              htmlFor="rating2-35"
                            >
                              <i className="rating__icon rating__icon--star fa fa-star-half" />
                            </label>
                            <input
                              className="rating__input"
                              name="rating2"
                              id="rating2-35"
                              defaultValue="3.5"
                              type="radio"
                            />
                            <label
                              aria-label="4 stars"
                              className="rating__label"
                              htmlFor="rating2-40"
                            >
                              <i className="rating__icon rating__icon--star fa fa-star" />
                            </label>
                            <input
                              className="rating__input"
                              name="rating2"
                              id="rating2-40"
                              defaultValue={4}
                              type="radio"
                            />
                            <label
                              aria-label="4.5 stars"
                              className="rating__label rating__label--half"
                              htmlFor="rating2-45"
                            >
                              <i className="rating__icon rating__icon--star fa fa-star-half" />
                            </label>
                            <input
                              className="rating__input"
                              name="rating2"
                              id="rating2-45"
                              defaultValue="4.5"
                              type="radio"
                            />
                            <label
                              aria-label="5 stars"
                              className="rating__label"
                              htmlFor="rating2-50"
                            >
                              <i className="rating__icon rating__icon--star fa fa-star" />
                            </label>
                            <input
                              className="rating__input"
                              name="rating2"
                              id="rating2-50"
                              defaultValue={5}
                              type="radio"
                            />
                          </div> }


                        </div>
                        <div className="textarea-msg-submt mt-4">
                          <textarea placeholder="Type your query..." defaultValue={""} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 regfrm-bag-image d-none d-lg-block">
                    {/* <img src="images/fdbk-image.png" className="" alt="" /> */}
                    <img src={`${config.serviceUrl}/images/myownn/fdbk-image.png`} className="" alt="" />
                  </div>
                  <div className="col-md-12 mt-4">
                    <div className="foot-wse-btn-pt mt-4">
                      <div className="foot-info-fdbk">
                        <div className="know-more-text">
                          <a
                            href={config.knowMoreLink} className="knw-mr-track" target="_blank" rel="noopener noreferrer"
                          >
                            Know more
                          </a>
                        </div>
                      </div>
                      <div className="footer-bottom-btn-group">
                        <button
                          type="button"
                          className="back-to-main me-3"
                          onclick="location.href = 'order-information.html';"
                        > 
                        <Link to={'/customer/track-order'}> Back to Main </Link>
                          
                        </button>
                        <button
                          type=""
                          className="new-sbmt-off"
                          onClick={this.handleSubmit}
                        >
                           Submit 
                          
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>


            <CustomerFooter />

          </>          
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(Feedback)))