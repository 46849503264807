import axios from 'axios'
import { Country, State, City }  from 'country-state-city';
import { cities, states } from '../utils/country-state';

class CountryService {
  constructor() {
    
    
  }

  getCountries() {
    return new Promise(resolve => {
      try {
        let data = Country.getAllCountries()
        for(let i = 0; i < data.length; i++) {
          data[i]["label"] = data[i].name
          data[i]["value"] = data[i].name
        }
        return resolve({success: true, countries: data})
      } catch (error) {
        return resolve({success: false})
      }
    })
  }

  getStates(isoCode) {
    return new Promise(resolve => {
      try {
        let data = states
        // let data = State.getStatesOfCountry(isoCode)
        console.log(data, 777777);
        for(let i = 0; i < data.length; i++) {
          data[i]["label"] = data[i].name
          data[i]["value"] = data[i].name
        }
        return resolve({success: true, states: data})
      } catch (error) {
        return resolve({success: false})
      }
    })
  }

  getTowns(countryCode, stateCode) {
    return new Promise(async resolve => {
      try {
        const _cities = await cities
        console.log(countryCode, stateCode, _cities, 7876765465);
        let data = []
        if(_cities.length) {
          data = await _cities.filter(city => city.countryCode == countryCode && city.stateCode == stateCode)
        }
        console.log(data, 88888);
        for(let i = 0; i < data.length; i++) {
          data[i]["label"] = data[i].name
          data[i]["value"] = data[i].name
        }
        return resolve({success: true, towns: data})
      } catch (error) {
        return resolve({success: false})
      }
    })
  }

}

export default new CountryService()