import React from "react";
export default function ShippingAddress({customer}){
    console.log("><><><><><>",customer)
    return (
        <div className="shipng-address">
            <h5>Shipping Address</h5>
            <h6>{customer && customer.name}</h6>
            <p>
                Street: {customer && customer.address && customer.address.addressLine1}
                
                <br />
                City: { customer && customer.address && customer.address.town}
                <br />
                PIN: { customer && customer.address && customer.address.pin}
                <br />
                State/province/area: {customer && customer.address && customer.address.state}
                <br />
                Phone number {customer && customer.mobileno}
            </p>
        </div>
    )
}