import React, { Component } from 'react'
import $ from 'jquery';
import moment from 'moment';
import { format } from 'date-fns';

class CustomDatatable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [],
            data: [],
            pagination: false,
            selectable: false,
            handleTrackOrderClick: undefined,
            handleTrackOrder: undefined,
            pageName: null,
            handleStatusChangeOnClick: undefined
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            columns: props.columns || [],
            data: props.data || [],
            pagination: props.hasOwnProperty("pagination") ? props.pagination : false,
            selectable: props.hasOwnProperty("selectable") ? props.selectable : false,
            handleTrackOrderClick: props.handleTrackOrderClick ? props.handleTrackOrderClick : null,
            handleTrackOrder: props.handleTrackOrder ? props.handleTrackOrder : null,
            handleStatusChangeOnClick: props.handleStatusChangeOnClick ? props.handleStatusChangeOnClick : undefined,
            pageName: props.pageName ? props.pageName : null


        }
    }

    componentDidMount = () => {

        console.log(this.props.columns, "columns");
        console.log(this.props.data, "data");
        // Initialize the DataTable when the component mounts
        // if(this.state.data.length) {
        $('#dt-tbl').DataTable({
            searching: true, // Enable search functionality
            paging: true, // Enable pagination
            // sorting: true, 
        },);
        // }
    }

    handleClick = (orderid, actiontype) => {
        // Call the function passed from the parent
        if(this.state.handleStatusChangeOnClick) {
            this.state.handleStatusChangeOnClick(orderid, actiontype);
        }
    };

    // handleCheckStatus = (item) => {

    //     console.log(item, "123");
    //     // Call the function passed from the parent
    //     this.props.handleOnChangeStatus(item);
    // };



    componentWillUnmount() {
        // Destroy the DataTable when the component unmounts
        // $(this.tableRef).DataTable().destroy();
        $('#dt-tbl').DataTable().destroy();
    }

    render = () => {
        const { columns, data, pagination, selectable, pageName } = this.props
        const { HandleOnChangeStatus } = this.state
        console.log(this.state, "dataallalllllll");
        return data.length && columns.length && (
            <table id="dt-tbl" className="display" style={{ width: "100%" }}>
                <thead>
                    <tr>
                        {columns.map((item, index) => (
                            <th key={`col-${index}`}>{item.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>



                    {pageName && pageName == "orderhistory" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>
                            <td>ID-{item.order_id}</td>
                            <td>{moment(item.collection_date).format('MM-DD-YYYY')}</td>
                            <td>Address-{item.address}</td>
                            <td></td>
                            <td>
                                <button type="button" className="landing-pending-btn">
                                    {item.orderStatus}
                                </button>
                            </td>
                            <td >
                                <div className="track-order-btn">
                                    <a href="javscript:void(0);" onClick={this.state.handleTrackOrderClick} name={item._id}> Track Order </a>
                                </div>
                            </td>

                        </tr>

                    )) : null}

                    {pageName && pageName == "mrlanding" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>


                            <td>{item.stockist_name}</td>
                            <td>{item.doctor_name}</td>
                            <td>
                                <p>{item.customer_name}</p>
                                <p>Mob-{item.customer_mobile}</p>
                            </td>
                            <td>{item.collection_date}</td>
                            <td>{item.grn_date}</td>
                            <td></td>
                            <td>
                                {<button type="button" className={item.order_status != 'complete' ? "landing-pending-btn" : "landing-success-btn"} onClick={this.state.handleTrackOrder} name={item._id}>
                                    {item.order_status}</button>}
                            </td>


                        </tr>

                    )) : null}


                    {pageName && pageName == "stockistlanding" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>


                            <td>{item.mr_name}</td>
                            <td>{item.doctor_name}</td>
                            <td>
                                <p>{item.customer_name}</p>
                                <p>Mob-{item.customer_mobile}</p>
                            </td>
                            <td>{item.grn_date}</td>
                            <td></td>
                            <td>
                                {<button type="button" className={item.order_status != 'complete' ? "landing-pending-btn" : "landing-success-btn"} onClick={this.state.handleTrackOrder} name={item._id}>
                                    {item.order_status}</button>}
                            </td>


                        </tr>

                    )) : null}



                    {pageName && pageName == "doctorlanding" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>


                            <td>{item.mr_name}</td>
                            <td>
                                <p>{item.customer_name}</p>
                                <p>Mob-{item.customer_mobile}</p>
                            </td>
                            <td>{item.collection_date}</td>

                            <td>
                                {<button type="button" className={item.srldetails > 0 ? "landing-success-btn" : "landing-pending-btn"} onClick={this.state.handleTrackOrder} name={item._id}>
                                    {item.srldetails > 0 ? "Successfull" : "Pending"}</button>}
                            </td>


                        </tr>

                    )) : null}

                    {pageName && pageName == "callcenterlanding" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>


                            <td>
                                <p>{item.customer_name}</p>
                                <p>Mob-{item.customer_mobile}</p>
                                <p>Address-{item.customer_address}</p>
                            </td>
                            <td>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <p>ID-{item.order_id}</p>
                                        <p>{item.product_name}</p>
                                    </div>

                                    <div className='col-md-6'>
                                        <p>{item.product_price}x{item.product_quantity}</p>
                                    </div>

                                </div>


                            </td>

                            <td>
                                {<button type="button" className={item.order_status != 'complete' ? "landing-pending-btn" : "landing-success-btn"} onClick={this.state.handleTrackOrder} name={item._id}>
                                    {item.order_status}</button>}
                            </td>


                        </tr>

                    )) : null}


                    {pageName && pageName == "manufacturlanding" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>
                            <td>ID-{item.order_id}</td>
                            <td>{item.order_date}</td>
                            <td>{item.mr_name}</td>
                            <td>
                                <p className="text-uppercase">{item.product_name}</p>
                                <p>Qty -  {item.product_quantity}</p>
                            </td>
                            <td>
                                <div className="d-flex justify-content-center">

                                    {
                                        item.GRN_STATUS === 'accept' ?
                                            <button type="button" className="landing-success-btn text-center w-110">Accepted</button>
                                            : item.GRN_STATUS === "reject" ?
                                                <button type="button" className="landing-pending-btn text-center w-110">Rejected</button>
                                                : <div className="d-flex justify-content-center">
                                                    <a href='javascript:void(0)' onClick={() => this.handleClick(item.order_id, "reject")} className="landing-pending-btn text-center w-110 me-1">Reject</a>
                                                    <a href='javascript:void(0)' onClick={() => this.handleClick(item.order_id, "accept")} className="landing-success-btn text-center w-110 ms-1">Accept</a>
                                                </div>
                                    }

                                    {/* <button type="button" className="landing-success-btn w-110">
                                        Accepted
                                    </button>

                                    <a href='javascript:void(0)' onClick={() => this.handleClick(item.order_id, "reject")} className="landing-pending-btn text-center w-110 me-1">
                                        Reject
                                    </a>
                                    <a href='javascript:void(0)' onClick={() => this.handleClick(item.order_id, "accept")} className="landing-success-btn text-center w-110 ms-1">
                                        Accept
                                    </a> */}
                                </div>
                            </td>


                        </tr>

                    )) : null}

                </tbody>
            </table>

        )
    }
}

export default CustomDatatable