import React from 'react'
import Swal from 'sweetalert2';

function Common(OriginalComponent) {
  class NewComponent extends React.Component {
    constructor(props) {
      super(props);
    }

    common = {
      makeId: (length) => {
        var result = "";
        var characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        return result;
      },
      verifyEmail: (email) => {
        if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(email)) {
          return true;
        } else {
          return false;
        }
      },
      stringValidator: (val) => {
        if (/[`~0-9!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi.test(val)) {
          return true;
        }
        else {
          return false;
        }
      },
      numbericValidator: (val) => {
        if (/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi.test(val)) {
          return true;
        }
        else {
          return false;
        }
      },
      customAlert: (message = null, success = true, title = null, cancelBtn = false, closeBtn = true, confirmFn = undefined, cancelFn = undefined) => {
        if(message) {
          let options = {
            showCloseButton: closeBtn,
            html: `<p>${message}</p>`,
            showCancelButton: cancelBtn,
            icon : success ? "success" : "error",
            confirmButtonText:
              'Ok',
            cancelButtonText:
              'cancel',
          }
          options = title ? {...options, title} : options
          Swal.fire(options).then(data => {
            if(data.isConfirmed) {
              if(confirmFn) {
                confirmFn()
              }
            }
            if(data.isDismissed) {
              if(cancelFn) {
                cancelFn()
              }
            }
          })
        }
      },
      customAlertWithClick: (message = null, success = true, title = null, cancelBtn = false, closeBtn = true, confirmFn = undefined, cancelFn = undefined) => {
        if(message) {
          let options = {
            showCloseButton: closeBtn,
            html: `<p>${message}</p>`,
            showCancelButton: cancelBtn,
            icon : success ? "success" : "error",
            confirmButtonText:
              'Ok',
            cancelButtonText:
              'cancel',
          }
          options = title ? {...options, title} : options
          Swal.fire(options).then((result) => {
            if (result.isConfirmed) {
              
                  // Code to execute after clicking "OK" or closing the alert
                  this.props.navigate('/customer/orders');
              // You can also perform a redirect or any other action here
             
              
            }
          });
        }
      }
    };

    render = () => <OriginalComponent {...this.props} hoc={this.common} />
  }
  return NewComponent;
}

export default Common;
