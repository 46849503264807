import {
    SET_MANUFACTUR,
    CLEAR_STATE
    } from '../types/Manufactur.type'
    class ManufacturAction {
      setManufactur = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        return { type: SET_MANUFACTUR, payload: data }
      }
      
      logout = ()=>{
        return { type: CLEAR_STATE, payload: null }
      }
     
      
    }
    export default new ManufacturAction()