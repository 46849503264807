import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  MANUFACTUR_DETAIL,
  MANUFACTUR_INFO,
  CHANGE_GRN_STATUS
} from './Slugs'
class ManufacturService extends Base {
  constructor(props) {
    super(props)
  }

 
  getManufacturDetails(data) {
    return this.post(MANUFACTUR_DETAIL,data);
  }
  getManufacturInfo(data) {
    return this.post(MANUFACTUR_INFO,data);
  }
  changeGRNStatus(data) {
    return this.post(CHANGE_GRN_STATUS,data);
  }

}

export default new ManufacturService()