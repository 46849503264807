import {
  PLACE_ORDER,
  LOGOUT,
  SAVE_ALL_ORDER,
  SAVE_SELECTED_ORDERS,
  CLEAR_ORDER_STATE
} from '../types/Order.type'

const initState = {
  currentOrder: {},
  orders: [],
  allOrders: [],
  selectedOrders: []
}

export default (state = initState, action) => {
  const { type, payload } = action
  switch (type) {
    case PLACE_ORDER:
      return Object.assign({}, state, { currentOrder: { ...payload } })
    case LOGOUT:
      return initState;
    case SAVE_ALL_ORDER:
      return Object.assign({}, state, { allOrders: { ...payload } })
    case SAVE_SELECTED_ORDERS:
      return Object.assign({}, state, { selectedOrders: { ...payload } })
    case CLEAR_ORDER_STATE:
      return Object.assign({}, initState)

    default:
      return state
  }
}