import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerAction from "../../../redux/actions/Customer.action";
import CustomerHeader from "../../../components/layout/CustomerHeader";
import CustomerFooter from '../../../components/layout/CustomerFooter';
import ModalCheckIcon from '../../../assets/Svg/ModalCheckIcon';
import BackButton from '../../../components/Reusable/BackButton';
import SuccessFullPaymentModal from '../../../components/Order/SuccessFullPaymentModal';
import OrderPlacedModal from '../../../components/Order/OrderPlacedModal';
class OrderPlaced extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    render = () => {
        const { currentOrder } = this.props.OrderReducer; 
        return (
                <>
                    <section className="checkout-cart-content">
                        <div className="container">
                            <CustomerHeader/>
                            {/* Row End*/}
                            <div className="row">
                                <div className="page-title-wrap">
                                    <h1 className="page-title">Payment method</h1>
                                </div>
                            </div>
                            {/* Row End*/}
                            <div className="radio-btn-group">
                                <div className="row align-items-center row-cols-2 row-cols-3">
                                    <div className="col">
                                        <div className="radio-content">
                                            <input
                                                type="radio"
                                                id="cards"
                                                className="form-controll radio-input"
                                                name="radio-group"
                                                defaultChecked=""
                                            />
                                            <label htmlFor="cards" className="radio-label radio-label-card">
                                                <img
                                                    className="credit-card-image"
                                                   
                                                    src={require("../../../assets/images/cart-details/card.png")}
                                                    alt="Credit Card"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    {/* <div className="col">
                                        <div className="radio-content">
                                            <input
                                                type="radio"
                                                id="payPal"
                                                className="form-controll radio-input"
                                                name="radio-group"
                                            />
                                            <label
                                                htmlFor="payPal"
                                                className="radio-label radio-label-paypal"
                                            >
                                                <img 
                                                    src={require("../../../assets/images/cart-details/paypal.png")}
                                                />
                                            </label>
                                        </div>
                                    </div> */}
                                    <div className="col">
                                        <div className="radio-content">
                                            <input
                                                type="radio"
                                                id="cod"
                                                className="form-controll radio-input"
                                                name="radio-group"
                                                defaultChecked={true}
                                            />
                                            <label htmlFor="cod" className="radio-label">
                                                {" "}
                                                Cod{" "}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="radio-content">
                                            <input
                                                type="radio"
                                                id="upi"
                                                className="form-controll radio-input"
                                                name="radio-group"
                                            />
                                            <label htmlFor="upi" className="radio-label radio-label-paypal">
                                                <img 
                                                    
                                                    src={require("../../../assets/images/cart-details/upi.png")} 
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Radio btn grou end*/}
                            <div className="row">
                                <div className="payment-dtls-form-title my-3 md-my-5">
                                    <h6>Payment Details</h6>
                                </div>
                                <form className="payment-details-form">
                                    <div className="mb-3">
                                        <label htmlFor="" className="form-label">
                                            Name on Card
                                        </label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="" className="form-label">
                                            Card Number
                                        </label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Valid Through (MM/YY)
                                                </label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="" className="form-label">
                                                    CVV
                                                </label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </form>
                                <div className="d-flex justify-content-end">
                                    <BackButton className="btn payment-dtls-submit px-4" />
                                   
                                    <button
                                        type="submit"
                                        className="btn payment-dtls-confirm px-4"
                                        data-bs-target="#orderPlaced"
                                        data-bs-toggle="modal"
                                    >
                                        Confirm
                                    </button>
                                </div>
                                {/* <div className="w-100 text-end">
                                    <button
                                        type="button"
                                        className="btn payment-dtls-confirm px-4 ms-4 ms-auto"
                                        onclick="location.href = 'order-information.html';"
                                    >
                                        Next Page
                                    </button>
                                </div> */}
                            </div>
                            {/* Row End*/}
                            {/* Row End*/}
                            
                            <CustomerFooter/>
                            {" "}
                            {/* Footer end*/}
                        </div>
                    </section>
                    {/* Modal payment successfull modal start */}
                    <SuccessFullPaymentModal dataTargetId={"orderPlaced"}/>
                    <OrderPlacedModal currentOrder={currentOrder} navigateUrl={"/customer/order-information"}/>
                </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        // dispatching plain actions
        CustomerReducer: state.CustomerReducer,
        OrderReducer: state.OrderReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderPlaced))