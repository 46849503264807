import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import MrHeader from "../../../components/layout/MR/MrHeader";
import MrAction from "../../../redux/actions/Mr.action";
import MrService from "../../../services/Mr.service";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import StockistHeader from "../../../components/layout/Stockist/StockistHeader";
import StockistService from "../../../services/Stockist.service";
import CallCenterService from "../../../services/CallCenter.service";
import OrderAction from "../../../redux/actions/Order.action";
import OtherService from "../../../services/OtherService";
class CallCenterList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            callcenterdetails: null,
            scallcenterid: "",
            callcenterList: [],
            callcenterinfo: "",
            callcenterdetailsList: ""
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            callcenterdetails: props._id || null,
        }
    }

    componentDidMount() {

        const callcenterdetails = this.props.callcenterdetails;
        const callcenter_id = callcenterdetails._id;
        let details = {
            callcenter_id: callcenter_id
        };
        console.log(details, "callcenter details 1",);
        if (callcenterdetails) {


            CallCenterService.getCallCenterInfo(details).then(data => {
                console.log(data.data, "callcenter info");

                if (data.data.success && data.data.data.callcenterinfo !== '') {
                    console.log(data.data.data.callcenterinfo[0], "okkkkk");

                    this.setState({ callcenterinfo: data.data.data.callcenterinfo[0] });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })


            CallCenterService.getCallCenterDetails(details).then(data => {
                console.log(data.data, "callcenter details 2");

                if (data.data.success && data.data.data.callcenterdetails !== '') {
                    console.log(data.data, "okkkkk");

                    this.setState({ callcenterdetailsList: data.data.data.callcenterdetails });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        } else {


        }



    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            resp.push({
                _id: data[i]._id,
                customer_name: data[i].customer.name,
                customer_mobile: data[i].customer.mobileno,
                customer_address: data[i].customer.address.addressLine1 + "," + data[i].customer.address.town + "," + data[i].customer.address.state + "," + data[i].customer.address.country + "," + data[i].customer.address.pin,
                order_id: data[i].order_id,
                product_name: data[i].products[0].name,
                product_price: data[i].products[0].price,
                product_quantity: data[i].products[0].quantity,
                order_status: data[i].orderStatus ? data[i].orderStatus : "",

            })
        }
        return resp
    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    handleTrackOrder = (event) => {

        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        try {
            OtherService.getOrderDetails({ _id: targetId }).then(data => {
                console.log(data.data, "order details 123456789");
                if (data.data.success) {
                    console.log(data.data.data.order, "okkkkk 123456789");
                    this.props.saveSelectedOrders(data.data.data.order)
                    this.props.navigate('/callcenter/track-info-order');
                    // this.setState({mrdetailsList:data.data.data.mrdetails});
                }
            })
        } catch (error) {

        }

    }

    render = () => {
        let _data = this.setOrderHistoryData(this.state.callcenterdetailsList)

        // console.log(this.state.mrdetailsList,"mrdetailsListsfsd");
        return (

            <>

                <section className="landing-profl-detls">
                    <div className="container">
                        <StockistHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="landing-page-pt pt-3 pb-4">
                                <div className="container">
                                    <div className="mr-landing-page">
                                        <div className="landing-head-pt">
                                            <h2>Call Center</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{" "}
                        {/* Row end*/}
                        <div className="landing-form-dtls">
                            <h5 className="mb-20">Customer & Order Details</h5>
                            <div className="table-responsive">
                                {/* <table
                                    id="example"
                                    className="table table-striped tbl-chng-pt table-responsive"
                                    style={{ width: "100%" }}
                                >
                                    <thead>
                                        <tr>
                                            <th>Patient Details</th>
                                            <th>Order Details</th>
                                            <th />
                                            <th>Order Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>Bimal Das</p>
                                                <p>Mob-9876554321 z</p>
                                                <p>Address-Ulltodanga Baguiati ,kol-157</p>
                                            </td>
                                            <td>
                                                <p>ID-123456</p>
                                                <p>EMROK - Injection (I.V) 800mg/100ml</p>
                                                <p>EMROK O - Tablets 500mg</p>
                                            </td>
                                            <td>15000,00x2</td>
                                            <td>
                                                <button type="button" className="landing-success-btn">
                                                    Delivered
                                                </button>
                                            </td>
                                        </tr>
                                       
                                    </tbody>
                                </table> */}

                                {
                                    _data && _data.length ?
                                        <CustomDatatable
                                            pagination={true}
                                            columns={[
                                                {
                                                    name: 'Patient Details',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Order Details',
                                                    // selector: row => row.doctor_name,

                                                },

                                                {
                                                    name: 'Order status',
                                                    // selector: row => row.createdAt,

                                                }
                                            ]}
                                            data={this.setOrderHistoryData(this.state.callcenterdetailsList)}
                                            pageName="callcenterlanding"
                                            handleTrackOrder={this.handleTrackOrder}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <CustomerFooter />



            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails))
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, CallCenterReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { callcenterdetails } = CallCenterReducer

    console.log(customer, 123);
    console.log(callcenterdetails, 123456);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        callcenterdetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(CallCenterList)))