import { createStore, combineReducers, applyMiddleware } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
//reducers
import CommonReducer from "./reducers/Common.reducer";
import CustomerReducer from "./reducers/Customer.reducer";
import OrderReducer from "./reducers/Order.reducer";
import MrReducer from "./reducers/Mr.reducer";
import DoctorReducer from "./reducers/Doctor.reducer";
import StockistReducer from "./reducers/Stockist.reducer";
import CallCenterReducer from "./reducers/CallCenter.reducer";
import ManufacturReducer from "./reducers/Manufactur.reducer";
const appReducer = combineReducers({
  CommonReducer: CommonReducer,
  CustomerReducer: CustomerReducer,
  OrderReducer: OrderReducer,
  MrReducer: MrReducer,
  DoctorReducer: DoctorReducer,
  StockistReducer: StockistReducer,
  CallCenterReducer: CallCenterReducer,
  ManufacturReducer: ManufacturReducer,
  
});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

let verifyToken = (store) => {
  return next => {
    return action => {
      return next(action)
    }
  }
}

let store = createStore(persistedReducer, applyMiddleware(verifyToken))
let persistor = persistStore(store)
export { store, persistor }
