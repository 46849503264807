import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  CALL_CENTER_DETAIL,CALL_CENTER_INFO
} from './Slugs'
class CallCenterService extends Base {
  constructor(props) {
    super(props)
  }

 
  getCallCenterDetails(data) {
    return this.post(CALL_CENTER_DETAIL,data);
  }
  getCallCenterInfo(data) {
    return this.post(CALL_CENTER_INFO,data);
  }

}

export default new CallCenterService()