import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  MR_LOGIN_API, MR_DETAIL,MR_INFO, UPDATE_CUSTOMER, CREATE_ORDER, UPLOAD_PRESCRIPTION, GET_PRESCRIPTION
} from './Slugs'
import MrAction from '../redux/actions/Mr.action';
// import config from '../../config/knorish.config';
// import AuthAction from '../redux/actions/Auth.action'
// import Validation from '../models/index.model'
// import { STRINGS } from '../utils/strings';
// import TenantInfoAction from '../redux/actions/TenantInfo.action';

class MrService extends Base {
  constructor(props) {
    super(props)
  }

  mrLogin(data) {
    return this.post(MR_LOGIN_API, data);
  }

  getmrDetails(data) {
    return this.post(MR_DETAIL,data);
  }
  getmrInfo(data) {
    return this.post(MR_INFO,data);
  }

}

export default new MrService()