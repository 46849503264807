import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import MrHeader from "../../../components/layout/MR/MrHeader";
import DoctorHeader from "../../../components/layout/Doctor/DoctorHeader";
import MrAction from "../../../redux/actions/Mr.action";
import MrService from "../../../services/Mr.service";
import DoctorService from "../../../services/Doctor.service";
import DoctorAction from "../../../redux/actions/Doctor.action";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import OtherService from "../../../services/OtherService";
import OrderAction from "../../../redux/actions/Order.action";
class DoctorDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            doctordetails: null,
            doctorid: "",
            doctordetailsList: [],
            doctorinfo: ""
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            doctordetails: props._id || null,
        }
    }

    componentDidMount() {

        const doctordetails = this.props.doctordetails;

        console.log(doctordetails,"doctordetails list");
         const doctor_id = doctordetails._id;
        let details = {
            doctor_id: doctor_id
        };
        // console.log(details, "doctor details",);
        if (doctordetails) {


            DoctorService.getdoctorInfo(details).then(data => {
                console.log(data.data, "doctor info");

                if (data.data.success && data.data.data.mrinfo !== '') {
                    console.log(data.data.data, "okkkkk");

                    this.setState({ doctordetailsList: data.data.data });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })


            DoctorService.getdoctorDetails(details).then(data => {
                console.log(data.data, "doctor details");

                if (data.data.success && data.data.data !== '') {
                    console.log(data.data, "okkkkk doctor details");

                    this.setState({ doctorinfo: data.data.data[0] });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        // } else {


        // }



    }
}


setOrderHistoryData = (data) => {
    let resp = []
    for (let i = 0; i < data.length; i++) {

        
        resp.push({
            _id: data[i]._id,
            mr_name: data[i].mr.name,
            customer_name: data[i].customer.name,
            customer_mobile: data[i].customer.mobileno,
            collection_date:data[i].collection_date?moment(data[i].collection_date).format('DD-MM-YYYY'):"",
            srldetails: data[i].srldetails.length
           
        })
    }
    return resp

    
}

componentWillUnmount() {
    if (this.dataTable) {
        this.dataTable.destroy(true);
    }
}

handleTrackOrder = (event) => {

    // const allOrder = this.props.OrderReducer.allOrders;
    const targetId = event.target.name;
    console.log(targetId, "target ID");
    try {
        OtherService.getOrderDetails({ _id: targetId }).then(data => {
            console.log(data.data, "order details 123456789");
            if (data.data.success) {
                console.log(data.data.data.order, "okkkkk 123456789");
                this.props.saveSelectedOrders(data.data.data.order)
                this.props.navigate('/doctor/track-info-order');
                // this.setState({mrdetailsList:data.data.data.mrdetails});
            }
        })
    } catch (error) {

    }

}

    render = () => {

        let _data = this.setOrderHistoryData(this.state.doctordetailsList)
        return (

            <>

                <section className="landing-page-pt">
                    <div className="container">
                        <DoctorHeader />
                        <div className="mr-landing-page">
                            <div className="landing-head-pt">
                                <h2>Doctor Landing page</h2>
                            </div>
                        </div>
                        <div className="landing-form-dtls">
                            <h5 className="mb-20">DR- {this.state.doctorinfo.name}</h5>
                            {
                                    _data && _data.length ?
                                    <CustomDatatable
                                        pagination={true}
                                        columns={[
                                            {
                                                name: 'MR Name',
                                                selector: row => row.stockist_name,

                                            },
                                            {
                                                name: 'Patient Detail',
                                                selector: row => row.doctor_name,

                                            },
                                            {
                                                name: 'Blood Collection',
                                                // selector: row => row.createdAt,

                                            },
                                            {
                                                name: 'Status',
                                                // selector: row => row.createdAt,

                                            }
                                        ]}
                                        data={this.setOrderHistoryData(this.state.doctordetailsList)}
                                        pageName="doctorlanding"
                                        handleTrackOrder={this.handleTrackOrder}
                                    />
                                    :null
                                }

                            {/* <div className="table-responsive">
                                <table
                                    id="example"
                                    className="table table-striped tbl-chng-pt table-responsive"
                                    style={{ width: "100%" }}
                                >
                                    <thead>
                                        <tr>
                                            <th width="">MR Name</th>
                                            <th width="">Patient Detail</th>
                                            <th width="">Blood Collection</th>
                                            <th width="">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>


                                        {this.state.doctordetailsList.map((item, index) => (
                                            <tr className="landing-frm-load">
                                                <td>{ item.mr.name }</td>
                                                <td>
                                                    <p className="mt-2">{ item.customer.name }</p>
                                                    <p className="">Mob-{ item.customer.mobileno }</p>
                                                </td>
                                                <td>{item.customer.collection_date?format(new Date(item.customer.collection_date), 'dd-MM-yyyy'):""}</td>
                                                
                                               
                                                <td>
                                                    <button type="button" className="landing-pending-btn">
                                                        Pending
                                                    </button>
                                                </td>
                                            </tr>

                                         ))} 

                                    </tbody>
                                </table>
                            </div> */}
                        </div>

                        <CustomerFooter />
                    </div>
                </section>


            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails))
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, DoctorReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { doctordetails } = DoctorReducer

    console.log(customer, 123);
 
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        doctordetails,
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(DoctorDetails)))