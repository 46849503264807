import React from 'react';
import './Loader.css'; // Import your loader styles

const Loader = () => {
  return (
    <div className="loader" style={{textAlign: 'right', marginRight: 20 }}>
      Loading...
    </div>
  );
};

export default Loader;
