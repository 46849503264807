import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import MrHeader from "../../../components/layout/MR/MrHeader";
import MrAction from "../../../redux/actions/Mr.action";
import MrService from "../../../services/Mr.service";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import StockistHeader from "../../../components/layout/Stockist/StockistHeader";
import ManufacturHeader from "../../../components/layout/Manufactur/ManufacturHeader";
import StockistService from "../../../services/Stockist.service";
import CallCenterService from "../../../services/CallCenter.service";
import ManufacturService from "../../../services/Manufactur.service";
class ManufacturList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            manufacturdetails: null,
            manufacturid: "",
            manufacturList: [],
            manufacturinfo: "",
            manufacturdetailsList: ""
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            manufacturdetails: props._id || null,
        }
    }

    componentDidMount() {

        const manufacturdetails = this.props.manufacturdetails;
        const manufactur_id = manufacturdetails._id;
        let details = {
            manufactur_id: manufactur_id
        };
        console.log(details, "manufactur details 1",);
        if (manufacturdetails) {


            ManufacturService.getManufacturInfo(details).then(data => {
                console.log(data.data, "manufactur info");

                if (data.data.success && data.data.data.manufacturinfo !== '') {
                    console.log(data.data.data.manufacturinfo[0], "okkkkk");

                    this.setState({ manufacturinfo: data.data.data.manufacturinfo[0] });
                }
                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
            this.getManufacturingDetails({ manufactur_id })


        } else {


        }



    }

    getManufacturingDetails = (details) => {
        ManufacturService.getManufacturDetails(details).then(data => {
            console.log(data.data, "manufactur details 2");

            if (data.data.success && data.data.data.manufacturdetails !== '') {
                console.log(data.data, "okkkkk");

                this.setState({ manufacturdetailsList: data.data.data.manufacturdetails });
            }
            // this.setState({mrdetailsList:data.data.data.mrdetails});

        }).catch(err => {

            console.log(err, "err")
        })
    }

    handleStatusChange = (orderid, status) => {

        // alert(orderid);

        try {

            if (orderid && status) {

                let details = {
                    order_id: orderid,
                    status: status

                };

                ManufacturService.changeGRNStatus(details).then(data => {
                   

                    if (data.data.success && data.data.data.grnstatusdetails !== '') {
                        console.log(data.data, "okkkkk");
                        const manufacturdetails = this.props.manufacturdetails;
                        const manufactur_id = manufacturdetails._id;
                        this.getManufacturingDetails({ manufactur_id })
                        // this.setState({ manufacturdetailsList: data.data.data.manufacturdetails });
                    }

                    if(!data.data.success){
                        console.log(data.data, "grnstatus details 2");
                        this.props.hoc.customAlert(data.data.message,false)
                    }
                    // this.setState({mrdetailsList:data.data.data.mrdetails});

                }).catch(err => {

                    console.log(err, "err")
                })
            }


        } catch (error) {

        }

        console.log(orderid, "orderid");
        console.log(status, "status");

    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            resp.push({
                _id: data[i]._id,
                order_id: data[i].order_id,
                order_date: data[i].createdAt ? moment(data[i].createdAt).format('DD-MMM-YYYY h:m a') : "",
                GRN_DATE: data[i].GRN_DATE,
                GRN_STATUS: data[i].GRN_STATUS,
                product_name: data[i].products[0].name,
                product_price: data[i].products[0].price,
                product_quantity: data[i].products[0].quantity,
                order_status: data[i].orderStatus ? data[i].orderStatus : "",
                mr_name: data[i].mr.name

            })
        }
        return resp
    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    render = () => {
        let _data = this.setOrderHistoryData(this.state.manufacturdetailsList)
        const columns = [
            {
                name: 'Order ID',
                // selector: row => row.mr_name,

            },
            {
                name: 'Date',
                // selector: row => row.doctor_name,

            },

            {
                name: 'MR Name',
                // selector: row => row.createdAt,

            },
            {
                name: 'Product Detail',
                // selector: row => row.createdAt,

            },
            {
                name: 'Status',
                // selector: row => row.createdAt,

            }
        ]
        // console.log(this.state.mrdetailsList,"mrdetailsListsfsd");
        return (

            <>

                <section className="landing-profl-detls">
                    <div className="container">
                        <ManufacturHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="landing-page-pt pt-3 pb-4">
                                <div className="container">
                                    <div className="mr-landing-page">
                                        <div className="landing-head-pt">
                                            <h2>Manufacturing</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{" "}
                        {/* Row end*/}
                        <div className="landing-form-dtls">
                            <h5 className="mb-20">Order Details</h5>
                            <div className="table-responsive">
                                {/* <table
                                    id="example"
                                    className="table table-striped tbl-chng-pt table-responsive"
                                    style={{ width: "100%" }}
                                >
                                    <thead>
                                        <tr>
                                            <th>Order ID</th>
                                            <th>Date &amp; Time</th>
                                            <th>MR Name</th>
                                            <th>Product Detail</th>
                                            <th className="text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="landing-frm-load">
                                            <td>ID-123456</td>
                                            <td>20-Jul-2023 I 08:25am </td>
                                            <td>Arun Pal</td>
                                            <td>
                                                <p className="text-uppercase">MYOWNN</p>
                                                <p>₹15,000.00 </p>
                                            </td>
                                            <td className="text-center">
                                                <button type="button" className="landing-success-btn w-110">
                                                    Accepted
                                                </button>
                                            </td>
                                        </tr>
                                        <tr className="landing-frm-load">
                                            <td>ID-123456</td>
                                            <td>20-Jul-2023 I 08:25am </td>
                                            <td>Arun Pal</td>
                                            <td>
                                                <p className="text-uppercase">MYOWNN</p>
                                                <p>₹15,000.00 </p>
                                            </td>
                                            <td className="text-center">
                                                <button type="button" className="landing-pending-btn w-110">
                                                    Rejected
                                                </button>
                                            </td>
                                        </tr>
                                        <tr className="landing-frm-load">
                                            <td>ID-123456</td>
                                            <td>20-Jul-2023 I 08:25am </td>
                                            <td>Arun Pal</td>
                                            <td>
                                                <p className="text-uppercase">MYOWNN</p>
                                                <p>₹15,000.00 </p>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-center">
                                                    <button type="button" className="landing-pending-btn w-110 me-1">
                                                        Rejected
                                                    </button>
                                                    <button type="button" className="landing-success-btn w-110 ms-1">
                                                        Accept
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> */}

                                {
                                    _data && _data.length ?
                                        <CustomDatatable
                                            pagination={true}
                                            columns={columns}
                                            data={this.setOrderHistoryData(this.state.manufacturdetailsList)}
                                            handleStatusChangeOnClick={this.handleStatusChange}
                                            pageName="manufacturlanding"
                                        />
                                        : null
                                }
                            </div>
                        </div>

                    </div>
                </section>
                <CustomerFooter />



            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, ManufacturReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { manufacturdetails } = ManufacturReducer

    console.log(customer, 123);
    console.log(manufacturdetails, 123456);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        manufacturdetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(ManufacturList)))