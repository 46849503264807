import {
  SET_CUSTOMER, SET_CONSENT_CHECKBOX, CLEAR_CONSENT_CHECKBOX, CLEAR_CUSTOMER_STATE
} from '../types/Customer.type'

const initState = {
  customer: {},
  token: null,
  consentValue: null
}

export default (state = initState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_CUSTOMER:
      return Object.assign({}, state, { customer: { ...payload } })
    case SET_CONSENT_CHECKBOX:
      return Object.assign({}, state, { consentValue: payload })
    case CLEAR_CONSENT_CHECKBOX:
      return Object.assign({}, state, { consentValue: null })
    case CLEAR_CUSTOMER_STATE:
      return Object.assign({}, initState)
    default:
      return state
  }
}