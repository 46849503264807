import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  STOCKIST_DETAIL,STOCKIST_INFO
} from './Slugs'
class StockistService extends Base {
  constructor(props) {
    super(props)
  }

 
  getStockistDetails(data) {
    return this.post(STOCKIST_DETAIL,data);
  }
  getStockistInfo(data) {
    return this.post(STOCKIST_INFO,data);
  }

}

export default new StockistService()