import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import MrHeader from "../../../components/layout/MR/MrHeader";
import MrAction from "../../../redux/actions/Mr.action";
import MrService from "../../../services/Mr.service";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import StockistHeader from "../../../components/layout/Stockist/StockistHeader";
import StockistService from "../../../services/Stockist.service";
import OtherService from "../../../services/OtherService";
import OrderAction from "../../../redux/actions/Order.action";
class StockistList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            stockistdetails: null,
            stockistid: "",
            stockistList: [],
            stockistinfo: "",
            stockistdetailsList: ""
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            stockistdetails: props._id || null,
        }
    }

    componentDidMount() {

        const stockistdetails = this.props.stockistdetails;
        const stockist_id = stockistdetails._id;
        let details = {
            stockist_id: stockist_id
        };
        console.log(details, "stockist details 1",);
        if (stockistdetails) {


            StockistService.getStockistInfo(details).then(data => {
                console.log(data.data, "stockist info");

                if (data.data.success && data.data.data.stockistinfo !== '') {
                    console.log(data.data.data.stockistinfo[0], "okkkkk");

                    this.setState({ stockistinfo: data.data.data.stockistinfo[0] });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })


            StockistService.getStockistDetails(details).then(data => {
                console.log(data.data, "Stockist details 2");

                if (data.data.success && data.data.data.stockistdetails !== '') {
                    console.log(data.data, "okkkkk");

                    this.setState({ stockistdetailsList: data.data.data.stockistdetails });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        } else {


        }



    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            resp.push({
                _id: data[i]._id,
                mr_name: data[i].mr.name,
                doctor_name: data[i].doctor.name,
                customer_name: data[i].customer.name,
                customer_mobile: data[i].customer.mobileno,
                grn_date: data[i].GRN_DATE ? moment(data[i].GRN_DATE).format('DD-MM-YYYY') : "",
                order_status: data[i].orderStatus ? data[i].orderStatus : "",

            })
        }
        return resp
    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    handleTrackOrder = (event) => {

        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        try {
            OtherService.getOrderDetails({ _id: targetId }).then(data => {
                console.log(data.data, "order details 123456789");
                if (data.data.success) {
                    console.log(data.data.data.order, "okkkkk 123456789");
                    this.props.saveSelectedOrders(data.data.data.order)
                    this.props.navigate('/stockist/track-info-order');
                    // this.setState({mrdetailsList:data.data.data.mrdetails});
                }
            })
        } catch (error) {

        }

    }

    render = () => {
        let _data = this.setOrderHistoryData(this.state.stockistdetailsList)

        // console.log(this.state.mrdetailsList,"mrdetailsListsfsd");
        return (

            <>

                <section className="landing-profl-detls">
                    <div className="container">
                        <StockistHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="landing-page-pt pt-3 pb-4">
                                <div className="container">
                                    <div className="mr-landing-page">
                                        <div className="landing-head-pt">
                                            <h2>Stockist Landing page</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{" "}
                        {/* Row end*/}
                        <div className="landing-form-dtls">
                            <h5 className="mb-20">{this.state.stockistinfo.name}</h5>
                            <div className="table-responsive">
                                {
                                    _data && _data.length ?
                                        <CustomDatatable
                                            pagination={true}
                                            columns={[
                                                {
                                                    name: 'MR Name',
                                                    selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Doctor Name',
                                                    selector: row => row.doctor_name,

                                                },
                                                {
                                                    name: 'Patient Details',
                                                    // selector: row => row.createdAt,

                                                },

                                                {
                                                    name: 'Shipment',
                                                    // selector: row => row.orderStatus,

                                                },
                                                {
                                                    name: 'Delivery',
                                                    // selector: row => row.orderStatus,

                                                },
                                                {
                                                    name: 'Status',
                                                    // selector: row => row.createdAt,

                                                }
                                            ]}
                                            data={this.setOrderHistoryData(this.state.stockistdetailsList)}
                                            handleTrackOrder={this.handleTrackOrder}
                                            pageName="stockistlanding"
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <CustomerFooter />



            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails))
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, StockistReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { stockistdetails } = StockistReducer

    console.log(customer, 123);
    console.log(stockistdetails, 123456);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        stockistdetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(StockistList)))