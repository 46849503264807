import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomerFooter from '../../../components/layout/CustomerFooter';
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerHeader from '../../../components/layout/CustomerHeader';
import config from '../../../config/emrok.config';
import { Link } from 'react-router-dom';


class OrderHistoryNew extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount = () => {

    }

    render = () => {
        return (
            <>
                <section className="landing-profl-detls">
                    <div className="container">
                        <CustomerHeader />
                        
                        <div className="row">
                        <div className="landing-page-pt pt-3 pb-4">
                            <div className="container">
                            <div className="mr-landing-page">
                                <div className="landing-head-pt">
                                <h2>Order History</h2>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>{" "}
                        {/* Row end*/}
                        <div className="landing-form-dtls">
                        <h5>All Order Details</h5>
                        <div className="table-responsive">
                            <table
                            id="example"
                            className="table table-striped tbl-chng-pt table-responsive"
                            style={{ width: "100%" }}
                            >
                            <thead>
                                <tr>
                                <th>Order ID</th>
                                <th>Total Amount</th>
                                <th style={{ width: "33.3333%" }}>Address</th>
                                <th className="text-center">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="landing-frm-load">
                                <td>ID-12345</td>
                                <td>₹60,000.00 </td>
                                <td>Address-Ulltodanga Baguiati ,kol-157</td>
                                <td className="text-center">
                                    <button type="button" className="landing-pending-btn">
                                    Pending
                                    </button>
                                </td>
                                </tr>
                                <tr>
                                <td>ID-12345</td>
                                <td>₹70,000.00</td>
                                <td>Address-Ulltodanga Baguiati ,kol-157</td>
                                <td className="text-center">
                                    <button type="button" className="landing-success-btn">
                                    Delivered
                                    </button>
                                </td>
                                </tr>
                                <tr>
                                <td>ID-12345</td>
                                <td>₹70,000.00</td>
                                <td>Address-Ulltodanga Baguiati ,kol-157</td>
                                <td className="text-center">
                                    <button type="button" className="landing-success-btn">
                                    Delivered
                                    </button>
                                </td>
                                </tr>
                                <tr>
                                <td>ID-12345</td>
                                <td>₹60,000.00 </td>
                                <td>Address-Ulltodanga Baguiati ,kol-157</td>
                                <td className="text-center">
                                    <button type="button" className="landing-pending-btn">
                                    Pending
                                    </button>
                                </td>
                                </tr>
                                <tr>
                                <td>ID-12345</td>
                                <td>₹70,000.00</td>
                                <td>Address-Ulltodanga Baguiati ,kol-157</td>
                                <td className="text-center">
                                    <button type="button" className="landing-success-btn">
                                    Delivered
                                    </button>
                                </td>
                                </tr>
                                <tr className="last-form-chng">
                                <td>ID-12345</td>
                                <td>₹70,000.00</td>
                                <td>Address-Ulltodanga Baguiati ,kol-157</td>
                                <td className="text-center">
                                    <button type="button" className="landing-success-btn">
                                    Delivered
                                    </button>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </section>

                <CustomerFooter />

          </>          
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderHistoryNew))