import React, {useEffect} from "react";
import Circle from "../../assets/Svg/Circle";
import ShppingJquery from "./ShippingJquery";
import { useNavigate } from 'react-router-dom';
export default function TrackShipment() {
    const navigate = useNavigate();
    useEffect(()=>{
        ShppingJquery();
    },[])


    const DisplayDateTimeComponent = () => {
        // Create a new Date object with the desired date and time (August 9, 2021, 10:00am)
        const date = new Date();
      
        // Define an array of month names
        const monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
      
        // Get the parts of the date (day, month, year)
        const day = String(date.getDate()).padStart(2, '0');
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
      
        // Get the parts of the time (hours, minutes)
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
      
        // Determine AM or PM
        const amOrPm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format
      
        // Format the date and time string (e.g., "09 Aug 2021, 10:00am")
        const formattedDateTime = `${day} ${month} ${year}, ${hours}:${minutes}${amOrPm}`;
      
        return (
          <div>
            {formattedDateTime}
          </div>
        );
      };

    return (
        <>
            <div className="col-md-6 track-shipping-ft mt-4">
                <div className="track-point-pt mt-4">
                    <h5>Track Shipment</h5>
                    <div className="col-md-12 col-lg-12">
                        <div id="tracking-pre" />
                        <div id="tracking">
                            <div className="tracking-list">
                                <div className="tracking-item">
                                    <div className="tracking-icon status-intransit">
                                        <Circle/>
                                    </div>
                                    <div className="tracking-content">
                                        Order placed<span>{DisplayDateTimeComponent()}</span>
                                    </div>
                                </div>
                                <div className="tracking-item">
                                    <div className="tracking-icon status-intransit">
                                        <Circle/>
                                    </div>
                                    <div className="tracking-content">
                                        Order confirmed<span>09 Aug 2021, 10:30am</span>
                                    </div>
                                </div>
                                <div className="tracking-item">
                                    <div className="tracking-icon status-intransit">
                                        <Circle/>
                                    </div>
                                    <div className="tracking-content">
                                        Out for delivery<span>09 Aug 2021, 12:00pm</span>
                                    </div>
                                </div>
                                <div className="tracking-item">
                                    <div className="tracking-icon status-intransit">
                                        <Circle/>
                                    </div>
                                    <div className="tracking-content">
                                        Delivered<span>10 Aug 2021, 02:00pm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dwn-btn-trk mt-4">
                        <div>
                            <button type="" className="feedbk-wrt" onClick={()=>{navigate(-1)}}>
                                Cancel
                            </button>
                        </div>
                        <div>
                            <button type="" className="feedbk-wrt" onclick="">
                                Track Order
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

