import React from "react";
import ModalCheckIcon from "../../assets/Svg/ModalCheckIcon";
export default function SuccessFullPaymentModal(props) {
    const {orderId, dataTargetId} = props
    console.log('SuccessFullPaymentModal ',orderId)
    return (
        <>
            <div
                className="modal modal-xs fade"
                id="paymentSuccessModal"
                // data-bs-target={"#"+dataTargetId}
                aria-hidden="true"
                aria-labelledby="paymentSuccessModalLabel"
                tabIndex={-1}
            >
                <div className="modal-dialog modal-dialog-centered modal-payment-success">
                    <div className="modal-content border-0">
                        <div className="modal-check-icon">
                            <ModalCheckIcon/>
                        </div>
                        <div className="modal-header border-0 pt-5 pb-2 mx-auto">
                            <h1
                                className="modal-title fs-5 text-center fw-bold"
                                id="paymentSuccessModalLabel"
                            >
                               <h1 class="modal-title fs-5 text-center fw-bold" id="paymentSuccessModalLabel">
              Your Order Has been placed!
            </h1>
                            </h1>
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div className="modal-body">
                            {/* <div className="row">
                                <div className="col-6 col-md-6">
                                    <div className="payment-label-left-column">
                                        <p>Payment Type</p>
                                        <p>Bank Name</p>
                                        <p>Mobile Number</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className="payment-label-right-column text-end">
                                        <p>Credit / Debit Card</p>
                                        <p>HDFC</p>
                                        <p>9876543210</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className="mt-3 payment-success-modal-footer">
                                        <h5 className="text-dark fw-semibold">Amount Paid</h5>
                                        <h5 className="text-dark fw-semibold">Due Amount</h5>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className="text-end mt-3 payment-success-modal-footer">
                                        <h5 className="text-success fw-semibold">₹20,100.00</h5>
                                        <h5 className="text-danger fw-semibold">₹40,000.00</h5>
                                    </div>
                                </div>
                            </div> */}
                            <p class="text-center">
                            You’ll get confirmation Message soon. Your item are now ready for
                            shipment.
                            </p>
                            <h1 class="modal-title fs-5 text-center fw-bold mx-auto" id="paymentSuccessModalLabel">
                            Order ID-{orderId}
                            </h1>
                        </div>
                        <div className="modal-footer border-0">
                            <button className="btn bg-primary w-100" 
                                    // data-bs-target={"#"+dataTargetId}
                                    data-bs-target="#paymentSuccessModal"
                                    data-bs-toggle="modal">
                                        Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}