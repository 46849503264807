import $ from "jquery";
export default  function ShppingJquery(){
    $(".tab").on("click", function (evt) {
        evt.preventDefault();
        $(this).toggleClass("active");
        var sel = this.getAttribute("data-toggle-target");
        $(".tab-content").removeClass("active").filter(sel).addClass("active");
    });

    $(function () {
        $(".master").css({
            height: $(".master img").height() + 13,
        });

        //make the width of the thumbnails images is dynamic
        var imagesNumber = $(".thumbnails").children().length,
            marginBetweenImages = 1,
            totalMargins = marginBetweenImages * (imagesNumber - 1),
            imageWidth = (100 - totalMargins) / imagesNumber;

        $(".thumbnails img").css({
            width: imageWidth + "%",
            marginRight: marginBetweenImages + "%",
        });
        $(".thumbnails img").on("click", function () {
            $(this).addClass("active").siblings().removeClass("active");
            $(".master img").hide().attr("src", $(this).attr("src")).fadeIn(300);
        });

        //use the chevron left and right to select images and translate between them
        $(".master .fas").on("click", function () {
            if ($(this).hasClass("fa-chevron-left")) {
                if ($(".thumbnails img.active").is(":first-child")) {
                    $(".thumbnails img:last-child").click();
                } else {
                    $(".thumbnails img.active").prev().click();
                }
            } else {
                if ($(".thumbnails img.active").is(":last-child")) {
                    $(".thumbnails img:first-child").click();
                } else {
                    $(".thumbnails img.active").next().click();
                }
            }
        });
    });
}