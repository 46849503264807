import {
    SET_STOCKIST,
    CLEAR_STATE
} from '../types/Stockist.type'

const initState = {
    stockistdetails: {},
    token: null
}

export default (state = initState, action) => {
    const { type, payload } = action
    switch (type) {
        case SET_STOCKIST:
            return Object.assign({}, state, { stockistdetails: { ...payload } })
        case CLEAR_STATE:
            return initState;
        default:
            return state
    }
}