import {
    SET_STOCKIST,
    CLEAR_STATE
    } from '../types/Stockist.type'
    class StockistAction {
      setStockist = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        return { type: SET_STOCKIST, payload: data }
      }
      
      logout = ()=>{
        return { type: CLEAR_STATE, payload: null }
      }
     
      
    }
    export default new StockistAction()