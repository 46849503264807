import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import MrHeader from "../../../components/layout/MR/MrHeader";
import MrAction from "../../../redux/actions/Mr.action";
import OrderAction from "../../../redux/actions/Order.action";
import MrService from "../../../services/Mr.service";
import OtherService from "../../../services/OtherService";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
class MrDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mrdetails: null,
            mrid: "",
            mrdetailsList: [],
            mrinfo:""
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            mrdetails: props._id || null,
        }
    }

    componentDidMount() {

        const mrdetails = this.props.mrdetails;
        const mr_id = mrdetails._id;
        let details = {
            mr_id: mr_id
        };
        console.log(details, "mr details 1",);
        if (mrdetails) {


            MrService.getmrInfo(details).then(data => {
                console.log(data.data, "mr info");

                if(data.data.success && data.data.data.mrinfo!==''){
                    console.log(data.data.data.mrinfo[0],"okkkkk");

                        this.setState({mrinfo:data.data.data.mrinfo[0]});
                }

                

                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })


            MrService.getmrDetails(details).then(data => {
                console.log(data.data, "mr details 2");

                if(data.data.success && data.data.data.mrdetails!==''){
                    console.log(data.data,"okkkkk");

                        this.setState({mrdetailsList:data.data.data.mrdetails});
                }

                

                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        } else {


        }



    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            resp.push({
                _id: data[i]._id,
                stockist_name: data[i].stockist.name,
                doctor_name: data[i].doctor.name,
                customer_name: data[i].customer.name,
                customer_mobile: data[i].customer.mobileno,
                collection_date:data[i].collection_date?moment(data[i].collection_date).format('DD-MM-YYYY'):"",
                grn_date:data[i].GRN_DATE?moment(data[i].GRN_DATE).format('DD-MM-YYYY'):"",
                order_status:data[i].orderStatus?data[i].orderStatus:"",
               
            })
        }
        return resp
    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    handleTrackOrder = (event) => {

        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        try {
            OtherService.getOrderDetails({_id:targetId}).then(data => {
                console.log(data.data, "order details 123456789");
                if(data.data.success){
                    console.log(data.data.data.order,"okkkkk 123456789");
                        this.props.saveSelectedOrders(data.data.data.order)
                        this.props.navigate('/mr/track-info-order');
                        // this.setState({mrdetailsList:data.data.data.mrdetails});
                }
            })
        } catch (error) {
            
        }

    }

    render = () => {
        let _data = this.setOrderHistoryData(this.state.mrdetailsList)

        console.log(_data,"mrdetailsListsfsd");
        return (

            <>

                <section className="landing-page-pt">
                    <div className="container">
                        <MrHeader />
                        <div className="mr-landing-page">
                            <div className="landing-head-pt">
                                <h2>MR Landing page</h2>
                            </div>
                        </div>
                        <div className="landing-form-dtls">
                            <h5>{this.state.mrinfo.name}</h5>

                            {
                                    _data && _data.length ?
                                    <CustomDatatable
                                        pagination={true}
                                        columns={[
                                            {
                                                name: 'Stockiest Name',
                                                selector: row => row.stockist_name,

                                            },
                                            {
                                                name: 'Doctor Name',
                                                selector: row => row.doctor_name,

                                            },
                                            {
                                                name: 'Patient Details',
                                                // selector: row => row.createdAt,

                                            },
                                            {
                                                name: 'Blood Collection',
                                                // selector: row => row.createdAt,

                                            },
                                            {
                                                name: 'Shipment',
                                                // selector: row => row.orderStatus,

                                            },
                                            {
                                                name: 'Delivery',
                                                // selector: row => row.orderStatus,

                                            },
                                            {
                                                name: 'Payment',
                                                // selector: row => row.createdAt,

                                            }
                                        ]}
                                        data={this.setOrderHistoryData(this.state.mrdetailsList)}
                                        handleTrackOrder={this.handleTrackOrder}
                                        pageName="mrlanding"
                                    />
                                    :null
                                }



                            {/* <div className="table-responsive">
                                <table
                                    id="example"
                                    className="table table-striped tbl-chng-pt table-responsive"
                                    style={{ width: "100%" }}
                                >
                                    <thead>
                                        <tr>
                                            <th width="">Stockiest Name</th>
                                            <th width="">Doctor Name</th>
                                            <th width="">Patient Details</th>
                                            <th width="">Blood Collection</th>
                                            <th width="">Shipment</th>
                                            <th width="">Delivery</th>
                                            <th width="">Payment</th>
                                        </tr>
                                    </thead>
                                    <tbody>


                                    {this.state.mrdetailsList.map((item, index) => (
                                        <tr className="landing-frm-load">
                                            <td>{item.stockist.name}</td>
                                            <td>{item.doctor.name}</td>
                                            <td>
                                                <p className="mt-2">{item.customer.name}</p>
                                                <p className="">Mob-{item.customer.mobileno}</p>
                                            </td>
                                            <td>{item.customer.collection_date?format(new Date(item.customer.collection_date), 'dd-MM-yyyy'):""}</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <button type="button" className="landing-pending-btn">
                                                    Pending
                                                </button>
                                            </td>
                                        </tr>

                                    ))}
                                      
                                    </tbody>
                                </table>
                            </div> */}
                        </div>

                        <CustomerFooter />
                    </div>
                </section>


            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, MrReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { mrdetails } = MrReducer
    console.log(customer, 123);
    console.log(mrdetails, 123456);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        mrdetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(MrDetails)))