import {
  LOADER_ACTION,
  TOKEN,
  LOGOUT
} from '../types/Common.type'
class CommonAction {
  getLoaderAction = (data) => {
    return { type: LOADER_ACTION, payload: data }
  }
  setToken = (data)=>{
    return { type: TOKEN, payload: data }
  }

  logout = ()=>{
    return { type: LOGOUT, payload: null }
  }
}
export default new CommonAction()