import {
    PLACE_ORDER,
    LOGOUT,
    SAVE_ALL_ORDER,
    SAVE_SELECTED_ORDERS,
    CLEAR_ORDER_STATE
  } from '../types/Order.type'
  class OrderAction {
    placeOrder = (data) => {
      return { type: PLACE_ORDER, payload: data }
    }

    logout = ()=>{
      return { type: LOGOUT, payload: null }
    }

    saveAllOrder = (data) => {
      return { type: SAVE_ALL_ORDER, payload: data }
    }
    saveSelectedOrders = (data) => {
      return { type: SAVE_SELECTED_ORDERS, payload: data }
    }

    clearState = () => {
      return { type: CLEAR_ORDER_STATE, payload: null }
    }
    
  }
  export default new OrderAction()